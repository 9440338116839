import React from "react";
import { Menu } from "./Menu";
import { Bottom } from "./footer/Bottom";
import wh from '../Components/imgs/titlecard2.png';
import loc from '../Components/progect1/MIGHTY STEEL WEBSITE USE/20201111_212717.jpeg';
import capture from "../Components/progect1/mslsteel.jpg";
import MIGHTYSTEELLINK2 from "../Components/progect1/MIGHTY STEEL LINK 2.jpg";
import "../Components/Style1.css";

export function About() {
    return (
        <>
            <Menu />
            <div className="container thome1">
                <div className="row step1 pt-2 pb-2">
                    <div className="col-12 topcard mb-3">
                        <div className="row g-0">
                            <div className="col-md-4 p-3">
                                <img src={loc} style={{ height: "330px", width: "100%" }} className="img-fluid rounded-start img1" alt="Location" />
                            </div>
                            <div className="col-md-8 d-flex flex-column">
                                <div className="wh p-2">
                                    <img src={wh} className="img-fluid rounded-start img1" alt="WH" />
                                </div>
                                <div className="card-body p-1 aboutsty">
                                    <h2 className="card-title">About Us</h2>
                                    <p className="card-text pt-2">
                                    <p>  <span className="toph">“SEAMINTECH SOLUTIONS”</span> was established in 2014 by K.Haribaskar - with the sole purpose of providing best class minerals and supreme quality Foundry products and service. He is having 30 years Experience in Various Foundries and Sleeves Manufacturing Units. Ever since, the company has managed to mark its presence in the industry by ensuring a cost-effective, safe, reliable and ethical service. </p>

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row step1 pt-2 pb-2">
                    <div className="col-12 topcard mb-3">
                        <div className="row g-0">
                            <div className="col-md-4 p-3">
                                {/* <img src={MIGHTYSTEELLINK2} style={{ height: "420px", width: "100%" }} className="img-fluid rounded-start img1" alt="Mighty Steel Link 2" /> */}
                            </div>
                            <div className="col-md-8 d-flex flex-column">
                                <div className="card-body p-1 aboutsty">
                                    <h2 className="card-title" style={{fontSize:"27px"}}>QUALITY POLICY</h2>
                                    <p className="card-text pt-2">
                                        <span className="toph">"Quality is our motto, and customer satisfaction is our benefit."</span> We have been trying our best to walk on the path of providing superior quality at most competitive prices. We supply the materials as per the requirements and specifications given by our customers. 
                                    </p>
                                </div>
                                {/* <img src={capture} style={{ height: "280px", width: "100%" }} className="img-fluid rounded-start img1 mt-2" alt="Capture" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </>
    );
}
