import React, { useState } from 'react';
import { Menu } from "./Menu";
import { Bottom } from "./footer/Bottom";
import smtgraphitecoat from "../Components/progect1/MIGHTY STEEL WEBSITE USE/seamintech/SMT-GRAPHITE COAT.jpg"
import smthicrcoating from "../Components/progect1/MIGHTY STEEL WEBSITE USE/seamintech/SMT-HICR COATING.jpg"
import red from '../Components/progect1/Red Iron Oxide.webp';
import fer from '../Components/progect1/Ferro Alloys.jpg';
import ml from '../Components/progect1/melting.jpeg';
import sle from '../Components/progect1/Sleeves.jpeg';
import FoundryCoating from "../Components/progect1/Foundry Coating.jpg"
import mcm from '../Components/progect1/Mould & Core Materials.jpg';
import GraphitePowder from "../Components/progect1/Graphite Powder.jpeg"
import feedingcompound from "../Components/progect1/feedingcompound.jpeg"
import Solvents from '../Components/progect1/solvents.jpg'
import ILLEMANITE from "../Components/progect1/ILLEMANITE.jpeg"
import Rutile from "../Components/progect1/Rutile.jpg";
import flour from "../Components/progect1/ZIRCON FLOUR 300 MESH .jpg"
import Ceramic from '../Components/progect1/Ceramic Sand.jpg';
import fil from '../Components/progect1/MIGHTY STEEL WEBSITE USE/FOAM FILTERS 3.jpg';
import zs from '../Components/progect1/MIGHTY STEEL WEBSITE USE/ZIRCON SAND OR GRADE.jpg';
import zf from '../Components/progect1/MIGHTY STEEL WEBSITE USE/ZIRCON SAND MK IREL.jpg';
import cs from '../Components/progect1/Chromite Sand.webp';
import slf from '../Components/progect1/Slica Flour.jpg';
import slm from '../Components/progect1/Siliminate.jpeg';
import per from '../Components/progect1/UntitlePerlite.jpeg';
import cp from '../Components/progect1/Calcite Power.jpg';
import cc from '../Components/progect1/China Clay.jpg';
import gr from '../Components/progect1/Gum Rosin.jpeg';
import rdco from '../Components/progect1/RDCO.webp';
import arccuttingrod from "../Components/progect1/Arc cutting Rod.jpg"
import ZIRCONSANDORGRADE from "../Components/progect1/MIGHTY STEEL WEBSITE USE/ZIRCON SAND OR GRADE.jpg"
import ferrosilicon from "../Components/progect1/ferrosilicon.jpg"
import ferromanganese from "../Components/progect1/ferromanganese.jpg"
import highcarbonferrochrome from "../Components/progect1/highcarbonferrochrome.jpeg"
import FerroSilicaCalcium from "../Components/progect1/FerroSilicaCalcium.jpg"
import FerroSilicaZirconium from "../Components/progect1/FerroSilicaZirconium.jpg"
import Seamins60 from "../Components/progect1/Seamins60(metal Additive Powder).webp"
import ceras60 from "../Components/progect1/MIGHTY STEEL WEBSITE USE/seamintech/sera-s60.jpg"
import perliteore from "../Components/progect1/perliteore.jpg"
import ZirconSandMKGrade from "../Components/progect1/ZirconSandMKGrade.jpg"
import ZirconSandQGrade from "../Components/progect1/ZirconSandQGrade.jpg"
import ZirconSandORGrade from "../Components/progect1/ZirconSandORGrade.jpg"
import ZirconSandKMML from "../Components/progect1/ZirconSandKMML.jpg"
import ZirconSandIMPORT from "../Components/progect1/ZirconSandimportGrade.webp"
import Chromite from '../Components/progect1/Chromite Sand1.jpg';
import EXCELCASTAV1600 from "../Components/progect1/MIGHTY STEEL WEBSITE USE/seamintech/EXCEL-CAST-AV1600.jpg"
import CeramicSandExcelCeracastS60 from "../Components/progect1/CeramicSandExcelCeracastS60.jpg"
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import "../product.css"
import SpecificationModal from './SpecificationModel';

export function Product() {
    
    const zirconSandSpecifications = [
        { item: 'ZrO2+HfO2', spec: '66.00%' },
        { item: 'SiO2', spec: '32.00%' },
        { item: 'Al2O3', spec: '0.50%' },
        { item: 'TiO2', spec: '0.50%' },
        { item: 'Fe2O3', spec: '0.30%' },
      ];
      const chromiteSandSpecifications = [
        { item: 'Cr2O3', spec: '55-57.0%' },
        { item: 'SiO2', spec: '0.5-0.7%' },
        { item: 'AFS', spec: '40-45' },  
      ];
      const FerroSiliconSpecifications=[
        { item: 'SILICON%', spec: '70.00-75.00' },
        { item: 'ALUMINIUM%', spec: '0.50-1.50' },
        { item: 'PHOSPHOROUS%', spec: '0.00-0.05' },
        { item: 'SULPHUR%', spec: '0.00-0.05' },
        { item: 'CARBON%', spec: '0.00-0.20' },
      ]
      const HighCarbonferrochromeSpecifications=[
        { item: 'CHROME', spec: '62.70%' },
        { item: 'C(CARBON)', spec: '8.70%' },
        { item: 'SI(SILICON)', spec: '1.73%' },
        { item: 'P', spec: '0.036%' },
        { item: 'S', spec: '0.040%' },
      ]
      const FerroSilicoCalciumSpecifications=[
        { item: 'SILICON%', spec: '60.38%' },
        { item: 'CALCIUM', spec: '30.95%' },
        { item: 'CARBON', spec: '0.47%' },
      ]
      
       const FerroSilicoZirconiumSpecifications=[
        { item: 'Zr', spec: '21.05%' },
        { item: 'Si', spec: '59.33%' },
        { item: 'C', spec: '0.04%' },
        { item: 'P', spec: '0.025%' },
        { item: 'SUL', spec: '0.03% Max' },
       ]
       const HighCarbonferroManganeseSpecifications=[
        { item: 'MANGANESE(Mn)', spec: '55.50%' },
        { item: 'C(CARBON)', spec: '6.08%' },
        { item: 'SI(SILICON)', spec: '0.217%' },
        { item: 'P(PHOSPHOROUS)', spec: '0.23%' },
        { item: 'SIZE', spec: '25X100MM' },
       ]
       const ceras60spec=[
        {item:'Physical Form' ,spec:'Liquid'	},
        {item:'Colour',spec: ' Green / Blue' },
        {item:'Sp.Gr. @ 25 Deg C' ,spec: '1.60- 1.70 gm/cc'},
        {item:'Viscocity  @ B4 Cu ', spec:'13 – 16 sec'},
        {item:'Baume',spec:  '60 ± 2' },
        {item:'PACKING ',spec: '30 KGS Carboys' },
        {item:'Self Life ' ,spec:' 1 year '},
         ]
     const HICR=[
            { item: 'Physical Form', spec: 'Liquid' },
            { item: 'Colour', spec: 'Black' },
            { item: 'Sp.Gr. @ 25 Deg C', spec: '1.10-1.20 gm/cc' },
            { item: 'Packing', spec: '25 KGS Carboys' },
            { item: 'Self Life', spec: '1 year' }
        ]
    const GRAP=[
        { item: 'Physical Form', spec: 'Liquid' },
        { item: 'Colour', spec: 'Black' },
        { item: 'Sp.Gr. @ 25 Deg C', spec: '1.60-1.70 gm/cc' },
        { item: 'Viscosity @ B4 Cu', spec: '13–16 sec' },
        { item: 'Baume', spec: '60 ± 2' },
        { item: 'Packing', spec: '30 KGS Carboys' },
        { item: 'Self Life', spec: '1 year' }
    ]
      const [showModal, setShowModal] = useState(false);
      const [modalTitle, setModalTitle] = useState('');
      const [currentSpecs, setCurrentSpecs] = useState([]);
      const handleShow = (title, specs) => {
        setModalTitle(title);
        setCurrentSpecs(specs);
        setShowModal(true);
      };
    
      const handleClose = () => setShowModal(false);
    const [selectedTab, setSelectedTab] = useState('tab1');

    const handleTabClick = (tabId) => {
        setSelectedTab(tabId);
    };
    const handleFerroAlloysClick = () => {
        setSelectedTab('tab7'); // Switch to Ferro Alloys tab (tab7)
    };
    const handleMeltingClick=()=>{
        setSelectedTab('tab8');
    }
    const handleMouldingclick=()=>{
        setSelectedTab('tab9');
    }
    const handleZirconClick=()=>{
        setSelectedTab('tab10')
    }
    const handlemcsclick=()=>{
        setSelectedTab('tab11')
    }
    return (
        <>   <div className='sticky-menu'>
            <Menu /></div>
            <SpecificationModal
        show={showModal}
        handleClose={handleClose}
        title={modalTitle}
        specifications={currentSpecs}
      />
            <div className='thome d-flex p-1'>
                <div className=" col-md-3  ">
                    <div className='dis sticky-categories'>
        
                        <div className='categories tabs11' >
                            <h2 style={{textAlign:"center",marginTop:"12px"}}>Categories</h2>
                            <ul>
                                <li><button className={selectedTab === 'tab1' ? 'active' : ''} onClick={() => handleTabClick('tab1')}><a href="#id">Foundry Raw Materials</a></button></li>
                                <li><button className={selectedTab === 'tab2' ? 'active' : ''} onClick={() => handleTabClick('tab2')}><a href="#id">Foundry Minerals</a></button></li>
                                <li><button className={selectedTab === 'tab11' ? 'active' : ''} onClick={() => handleTabClick('tab11')}><a href="#id">Foundry Coatings</a></button></li>
                                <li><button className={selectedTab === 'tab11' ? 'active' : ''} onClick={() => handleTabClick('tab11')}><a href="#id">Foundry Sleeves</a></button></li>
                                <li><button className={selectedTab === 'tab3' ? 'active' : ''} onClick={() => handleTabClick('tab3')}><a href="#id">Refractory Minerals</a></button></li>
                                <li><button className={selectedTab === 'tab4' ? 'active' : ''} onClick={() => handleTabClick('tab4')}><a href="#id">Welding Electrode Flux Materials</a></button></li>
                                <li><button className={selectedTab === 'tab5' ? 'active' : ''} onClick={() => handleTabClick('tab5')}><a href="#id">Foundry Coatings Raw Materials</a></button></li>
                                <li><button className={selectedTab === 'tab6' ? 'active' : ''} onClick={() => handleTabClick('tab6')}><a href="#id">Paint Chemicals</a></button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <p id="top"></p>
                <div className=" col-md-9  productimgsize" >
                    <p id="id"></p>
                    <div className="cards11">
                        <div className="card11" style={{ display: selectedTab === 'tab1' ? 'block' : 'none' }}>
                            <h2 className="d-flex justify-content-center">Foundry Raw Materials</h2>
                            <div className="row row-cols-1 row-cols-md-3 g-4">
                                <div className="col">
                                    <div className="card h-100" onClick={handleFerroAlloysClick} style={{ cursor: "pointer" }}>
                                        <img src={fer} className="card-img-top img-fluid" alt="Ferro Alloys"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Ferro Alloys</h5>
                                            <p>Ferro Alloys are iron-based alloys used to enhance the properties of steel and other alloys</p>
                                            <button style={{ marginLeft:"37px",marginTop: "45px", color: "white", backgroundColor: "red",borderRadius:"8px" }}>Products</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100" onClick={handleMeltingClick} style={{ cursor: "pointer" }}>
                                        <img src={ml} className="card-img-top img-fluid" alt="Melting Additives"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Melting Additives</h5>
                                            <p>Melting Additives are materials added during the melting process to improve metal quality and achieve desired properties in the final product.</p>
                                            <button style={{ marginLeft:"37px",color: "white", backgroundColor: "red", marginTop:"20px",borderRadius:"8px"}}>Products</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100" onClick={handleMouldingclick} style={{ cursor: "pointer" }}>
                                        <img src={ZIRCONSANDORGRADE} className="card-img-top img-fluid" alt="Moulding Minerals"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Moulding Minerals</h5>
                                            <p>Moulding Minerals are specialized minerals used in foundry processes to create high-quality molds and cores for metal casting.</p>
                                            <button style={{ marginLeft:"37px",color: "white", backgroundColor: "red",marginTop:"20px",borderRadius:"8px" }}>Products</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <img src={sle} className="card-img-top img-fluid" alt="Sleeves / Risers"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Sleeves / Risers</h5>
                                            <p>Sleeves/Risers are components used in metal casting to prevent shrinkage defects by providing a reservoir of molten metal to feed the casting as it solidifies.</p>
                                            <div style={{marginTop:"42px"}}>
                                                <h6 style={{color:"blue",fontWeight:"bold",paddingLeft:"15px"}}>FEEDTECH-SLEEVES</h6>
                                                <h6 style={{color:"blue",fontWeight:"bold"}}>(Highly Insulating Sleeves)</h6></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100 "onClick={handlemcsclick} style={{ cursor: "pointer" }}>
                                        <img src={FoundryCoating} className="card-img-top img-fluid" alt="Mould Coatings & Solvents"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Mould Coatings & Solvents</h5>
                                            <p>Mould Coatings & Solvents are materials applied to molds to enhance surface finish, prevent metal adhesion, and improve casting quality, while solvents are used to clean or prepare surfaces for coating.</p>
                                            <div style={{marginTop:"42px"}}>
                                                <h6 style={{color:"blue",fontWeight:"bold",paddingLeft:"15px"}}>SEAMINCOAT CERA-S60</h6>
                                                <h6 style={{color:"blue",fontWeight:"bold",paddingLeft:"15px"}}>(Zircon Solvent-Base Coating's)</h6>
                                                <button style={{ marginLeft:"37px",marginTop: "45px", color: "white", backgroundColor: "red",borderRadius:"8px" }}>Products</button>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <img src={feedingcompound} className="card-img-top img-fluid" alt="Feeding Compounds"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Feeding Compounds</h5>
                                            <p>Feeding Compounds are materials added to molds to ensure a continuous supply of molten metal during solidification, helping to prevent defects and improve the quality of the cast product.</p>
                                            <div style={{marginTop:"42px"}}>
                                                <h6 style={{color:"blue",fontWeight:"bold",paddingLeft:"15px"}}>HOTTOP-APC POWDER</h6></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <img src={fil} className="card-img-top img-fluid" alt="Ceramic Form Filters"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Ceramic Form Filters</h5>
                                            <p>Ceramic Form Filters are filtration devices used in metal casting to remove impurities and contaminants from molten metal, improving the quality and surface finish of the final casting.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-100">
                                        <img src={arccuttingrod} className="card-img-top img-fluid" alt="Consumables"/>
                                        <div className="card-body">
                                            <h5 className="card-title">Consumables</h5>
                                            <p>Consumables are materials or products used up in the production process, such as tools, coatings, or additives, which need regular replenishment to maintain operations.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            {/* <button style={{marginTop:"50px"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>Top</button> */}
                                                </div>
                                    </div>
                                </div>
                            </div>             
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab7' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Ferro Alloys</h2>
                            <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ferrosilicon} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Ferro Silicon</h5>
                                                <p>Ferro Silicon is an alloy of iron and silicon used primarily as a deoxidizer in steelmaking and to enhance the strength and magnetic properties of steel.</p>
                                                <button style={{marginTop:"2px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('Ferro Silicon Specification', FerroSiliconSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ferromanganese} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Ferro Manganese</h5>
                                                <p>Ferro Manganese is an alloy of iron and manganese used in steelmaking to improve strength, toughness, and resistance to wear.</p>
                                                <button style={{marginTop:"25px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('High Carbon Ferro Manganese Specification', HighCarbonferroManganeseSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={highcarbonferrochrome} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">High Carbon Ferro Chrome</h5>
                                               <p>High Carbon Ferro Chrome is an alloy of iron and chromium with a high carbon content, used to enhance the hardness and corrosion resistance of stainless steel and other alloys.</p>
                                               <button style={{marginTop:"2px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('High Carbon Ferro Chrome Specification', HighCarbonferrochromeSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={FerroSilicaCalcium} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Ferro Silica Calcium</h5>
                                                <p>Ferro Silica Calcium is an alloy of iron, silicon, and calcium used in steelmaking to improve fluidity, reduce sulfur, and enhance overall steel quality.</p>
                                                <button style={{marginTop:"2px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('High Carbon Ferro Chrome Specification', FerroSilicoCalciumSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={FerroSilicaZirconium} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Ferro Silica Zirconium</h5>
                                               <p>Ferro Silica Zirconium is an alloy of iron, silicon, and zirconium used to refine steel, improve grain structure, and enhance resistance to high temperatures and corrosion.</p>
                                               <button style={{marginTop:"2px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('High Carbon Ferro Chrome Specification', FerroSilicoZirconiumSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button>Top</button></a>
                                            <a href='#top'><button style={{marginTop:"1px",backgroundColor:"red"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>back</button></a>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab8' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Melting Additives</h2>
                            <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ml} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Seamin-s60(metal Additive Powder)</h5>
                                                <p>SEAMIN-S60 is a metal additive powder used to improve the properties of molten metal, such as fluidity and casting quality, particularly in steel and iron foundries.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={perliteore} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Perlite Ore</h5>
                                               <p>Perlite Ore is a volcanic glass used as a lightweight aggregate in construction, as well as a flux and insulator in metal casting processes.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            <a href='#top'><button style={{marginTop:"1px",backgroundColor:"red"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>back</button></a>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab9' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Moulding Minerals</h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandMKGrade} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-"MK" Grade</h5>
                                               <p>Zircon Sand "MK" Grade is a high-quality zircon sand used in foundry applications to produce refractory materials and molds due to its excellent thermal stability and low thermal expansion.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandQGrade} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-"Q" Grade</h5>
                                                <p>Zircon Sand "Q" Grade is a premium-grade zircon sand used in foundry and refractory applications for its high thermal stability and resistance to thermal shock.</p>
                    
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandORGrade} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-"OR" Grade</h5>
                                               <p>Zircon Sand "OR" Grade is a high-grade zircon sand used primarily in foundries and refractory applications for its superior thermal properties and low thermal expansion.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandKMML} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-KMML</h5>
                                               <p>Zircon Sand-KMML is zircon sand sourced from the Kerala Minerals and Metals Ltd. (KMML) in India, used in foundries and ceramics for its high purity and thermal resistance.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandIMPORT} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-IMPORT</h5>
                                               <p>Zircon Sand-IMPORT refers to high-quality zircon sand imported from international suppliers, used in foundries and refractory applications for its excellent thermal stability and performance.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={Chromite} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Chromite Sand </h5>
                                               <p>Chromite Sand is a high-quality, refractory sand used in foundry and metallurgical applications for its excellent heat resistance, thermal conductivity, and ability to withstand high temperatures.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={CeramicSandExcelCeracastS60} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Ceramic Sand-Excel Ceracast-S60</h5>
                                                <p>Ceramic Sand-Excel Ceracast-S60 is a high-performance ceramic sand used in foundry applications for its excellent thermal stability, low thermal expansion, and superior casting surface finish.</p>
                                               
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            <a href='#top'><button style={{marginTop:"1px",backgroundColor:"red"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>back</button></a>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab2' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center" id='FondryMinerals'>Foundry Minerals <span><p> ( Sand & Investment Casting )</p></span></h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100" >
                                        <img src={zs} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand</h5>
                                               <p>Zircon Sand is a high-grade mineral used in foundry, ceramic, and refractory applications due to its excellent thermal stability, low thermal expansion, and resistance to high temperatures.</p>
                                            <button onClick={handleZirconClick} style={{ cursor: "pointer", marginLeft:"37px",color: "white", backgroundColor: "red" ,marginTop:"23px",borderRadius:"8px"}}>Products</button>
                                            <button style={{marginTop:"2px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('Zircon Sand Specification', zirconSandSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={flour} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Flour</h5>
                                                <p>Zircon Flour is a finely ground zircon sand used in ceramics, glass, and foundry applications to improve thermal stability, enhance material properties, and provide a smooth finish.</p>
                                                <div style={{marginTop:"42px"}}>
                                                <h6 style={{color:"blue",fontWeight:"bold"}}>Zircon Flour Q-IREL</h6>
                                                <h6 style={{color:"blue",fontWeight:"bold"}}>Zircon Flour-325 Mesh</h6></div>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={cs} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Chromite Sand</h5>
                                               <p>Chromite Sand is a high-grade refractory material used in foundries and metallurgical industries for its exceptional heat resistance, thermal conductivity, and ability to maintain structural integrity at high temperatures.</p>
                                               <h6 style={{color:"blue",fontWeight:"bold"}}>AFS-40-45</h6>
                                               <h6 style={{color:"blue",fontWeight:"bold"}}>AFS-60</h6>
                                               <button style={{marginTop:"2px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('Chromite Sand Specification', chromiteSandSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={Ceramic} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Ceramic Sand</h5>
                                               <p>Ceramic Sand is a type of high-performance sand used in foundry and refractory applications for its excellent thermal stability, resistance to thermal shock, and ability to produce high-quality castings.</p>
                                              <h6 style={{color:"blue",fontWeight:"bold"}}>Excel-Ceracast S60</h6>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={red} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Red Iron Oxide</h5>
                                               <p>Red Iron Oxide is a pigment and rust-resistant material used in paints, coatings, and construction products for its vibrant color and corrosion-resistant properties.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={EXCELCASTAV1600} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">EXCEL-CAST-AV1600</h5>
                                               <p>EXCEL-CAST-AV1600 is a high-performance ceramic coating used in foundries to enhance the durability and thermal resistance of casting molds.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            {/* <button style={{marginTop:"50px"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>Top</button> */}
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab10' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Zircon sand</h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandMKGrade} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-"MK" Grade</h5>
                                               <p>Zircon Sand "MK" Grade is a high-quality zircon sand used in foundry applications to produce refractory materials and molds due to its excellent thermal stability and low thermal expansion.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandQGrade} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-"Q" Grade</h5>
                                                <p>Zircon Sand "Q" Grade is a premium-grade zircon sand used in foundry and refractory applications for its high thermal stability and resistance to thermal shock.</p>                                                
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandORGrade} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-"OR" Grade</h5>
                                               <p>Zircon Sand "OR" Grade is a high-grade zircon sand used primarily in foundries and refractory applications for its superior thermal properties and low thermal expansion.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ZirconSandKMML} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand-KMML</h5>
                                               <p>Zircon Sand-KMML is zircon sand sourced from the Kerala Minerals and Metals Ltd. (KMML) in India, used in foundries and ceramics for its high purity and thermal resistance.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            <a href='#top'><button style={{marginTop:"1px",backgroundColor:"red"}} onClick={() => {
                                                setSelectedTab("tab2");
                                                }}>back</button></a>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab3' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Refractory Minerals</h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={zs} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Sand</h5>
                                               <p>Zircon Sand is a mineral used in foundry, ceramic, and refractory applications for its high thermal resistance, low thermal expansion, and durability under extreme conditions.</p>
                                               <button style={{marginTop:"2px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('Zircon Sand Specification', zirconSandSpecifications)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={cs} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Chromite</h5>
                                                 <p>Chromite is a mineral and key source of chromium, used primarily in the production of ferrochrome for stainless steel and as a refractory material due to its high heat resistance and stability.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={slm} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Slimanite</h5>
                                               <p>Slimanite is a type of mineral used in the production of high-quality refractories and in foundry applications due to its excellent thermal stability and resistance to thermal shock.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={per} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Perlite</h5>
                                               <p>Perlite is a volcanic glass that expands when heated, used in construction as a lightweight aggregate and in horticulture for its aeration properties, as well as in industrial applications for its insulation and filtering capabilities.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            {/* <button style={{marginTop:"50px"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>Top</button> */}
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab4' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Welding Electrode Flux Materials</h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={Rutile} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Rutile</h5>
                                               <p>Rutile is a titanium dioxide mineral used in various industries for its high refractive index and resistance to high temperatures, making it valuable in pigments, ceramics, and as a refractory material.</p>
                                               <h6 style={{color:"blue",fontWeight:"bold"}}>Grade:IREL</h6>
                                               <h6 style={{color:"blue",fontWeight:"bold"}}>Grade:KMML</h6>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ILLEMANITE} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Illemanite</h5>
                                                 <p>Ilmenite is a mineral primarily composed of iron titanium oxide, used as a major source of titanium dioxide in the production of titanium metal and pigments, and in various industrial applications due to its durability and heat resistance.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={zs} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon</h5>
                                               <p>Zircon is a mineral consisting of zirconium silicate, used in ceramics, foundries, and as a gemstone. It is valued for its high thermal stability, durability, and use in refractory materials.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={red} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Red Iron Oxide</h5>
                                                <p>Red Iron Oxide is a pigment used in paints, coatings, and construction materials for its vibrant red color and excellent durability and resistance to weathering.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={slf} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Slica Flour</h5>
                                               <p>Silica Flour is a fine powder of silicon dioxide used in various industrial applications, including concrete, ceramics, and foundry processes, to enhance strength, durability, and texture.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={cp} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Calcite Powder</h5>
                                               <p>Calcite Powder is a finely ground form of calcium carbonate used in various industries, including construction, agriculture, and manufacturing, to enhance properties like whiteness, opacity, and as a filler or additive.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={cp} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">China Clay</h5>
                                               <p>China Clay, also known as kaolin, is a fine, white clay used in ceramics, paper, and as a filler in various industrial products due to its smooth texture, plasticity, and ability to enhance brightness and opacity.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            {/* <button style={{marginTop:"50px"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>Top</button> */}
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab5' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Foundry Coatings Raw Materials</h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={Solvents} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Solvents</h5>
                                               <p>Solvents are liquids used to dissolve or dilute other substances, facilitating their processing or application in various industries, including cleaning, painting, and chemical manufacturing.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={flour} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Zircon Flour</h5>
                                                <p>Zircon Flour is a finely milled form of zircon sand used in ceramics, foundries, and glassmaking for its high thermal stability, durability, and ability to improve the quality and performance of the final products.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={cs} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Chromite Flour</h5>
                                               <p>Chromite Flour is a finely ground form of chromite ore used in foundries and refractory applications to enhance heat resistance, thermal conductivity, and the structural integrity of castings and refractory materials.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={flour} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Silimanite Flour</h5>
                                               <p>Sillimanite Flour is a finely ground form of sillimanite mineral used in high-temperature refractory applications for its excellent thermal stability, resistance to thermal shock, and ability to improve the performance and longevity of refractory materials.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={slf} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Silica Flour</h5>
                                               <p>Silica Flour is a finely ground form of silicon dioxide used in various applications, including concrete, ceramics, and foundry processes, to improve strength, durability, and texture.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={GraphitePowder} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Graphite Powder</h5>
                                               <p>Graphite Powder is a fine form of graphite used as a lubricant, in batteries, and in various industrial applications for its high conductivity, stability at high temperatures, and lubricating properties.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={gr} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Gum Rosin</h5>
                                               <p>Gum Rosin is a natural resin obtained from pine trees, used in adhesives, varnishes, and as a flux in soldering due to its sticky, solidifying properties and ability to enhance bonding.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            {/* <button style={{marginTop:"50px"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>Top</button> */}
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab6' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Paint Chemicals</h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={rdco} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">RDCO 50%</h5>
                                               <p>RDCO 50% typically refers to a type of resin or chemical solution with 50% active content, used in various industrial applications such as adhesives, coatings, or as a binder in different formulations.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={rdco} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">RDCO 70%</h5>
                                               <p>RDCO 70% generally refers to a resin or chemical solution with 70% active content, used in industrial applications like adhesives, coatings, or binders, offering a higher concentration of active ingredients for improved performance or efficiency.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={gr} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Gum Rosin</h5>
                                               <p>Gum Rosin is a natural resin derived from pine trees, used in adhesives, coatings, and as a flux in soldering due to its tacky consistency and ability to enhance bonding and adhesion.</p>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={red} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">Red Iron Oxide</h5>
                                               <p>Red Iron Oxide is a pigment used in paints, coatings, and construction materials for its vibrant red color and durability, offering excellent resistance to weathering and UV light.</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            {/* <button style={{marginTop:"50px"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>Top</button> */}
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card11" style={{ display: selectedTab === 'tab11' ? 'block' : 'none' }}>
                        <h2 class="d-flex justify-content-center">Paint Chemicals</h2>
                        <div class="row row-cols-1 row-cols-md-3 g-4 ">
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={ceras60} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">SEAMINCOAT CERA-S60</h5>
                                               <p>SEAMINCOAT CERA-S60 is a durable ceramic coating that offers superior protection against high temperatures and wear.</p>
                                               <button style={{marginTop:"45px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('SEAMINCOAT CERA S60 Specification', ceras60spec)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={smthicrcoating} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">SEAMIN - HICR COATING</h5>
                                               <p>SEAMIN - HICR Coating is a high-impact, corrosion-resistant coating designed to enhance the durability of industrial surfaces.</p>
                                               <button style={{marginTop:"45px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('SEAMIN - HICR COATING Specification', HICR)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={smtgraphitecoat} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">SEAMIN - GRAPHITE COATING</h5>
                                               <p>SEAMIN - GRAPHITE Coating is a high-performance coating that provides excellent lubrication and protection against wear and high temperatures.</p>
                                               <button style={{marginTop:"0px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('SEAMIN-GRAPHITE COAT  Specification', GRAP)}>
                                            SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={red} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">SEAMIN - MN COATING</h5>
                                               <p>SEAMIN - MN Coating is a manganese-based coating that offers superior abrasion resistance and enhances the longevity of industrial components.</p>
                                               
                                            </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card h-100">
                                        <img src={red} class="card-img-top" alt="..."/>
                                            <div class="card-body">
                                                <h5 class="card-title">SEAMIN - LADLE COATING</h5>
                                               <p>SEAMIN - LADLE Coating is designed to protect ladles from thermal shock and corrosion, extending their service life in high-temperature metal casting processes.</p>
                                               <button style={{marginTop:"25px",marginLeft:"37px",backgroundColor:"white",color:"red",border:"1px solid white",fontWeight:"bold"}}
                                            className="btn btn-primary mr-2"
                                            onClick={() => handleShow('High Carbon Ferro Manganese Specification', ceras60spec)}>
                                           SPECIFICATION
                                            </button>
                                            </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card h-10">
                                        <div className="card-body" style={{marginTop:"4%" ,marginLeft:"12%"}}>
                                            <a href="#top"><button style={{borderRadius:"8px"}}>Top</button></a>
                                            {/* <button style={{marginTop:"50px"}} onClick={() => {
                                                setSelectedTab("tab1");
                                                }}>Top</button> */}
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

