import React from "react";
import navelogo from '../progect1/MIGHTY STEEL WEBSITE USE/seamintech/navlogo.png';
import loc from '../progect1/MIGHTY STEEL WEBSITE USE/20201111_212717.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInternetExplorer } from "@fortawesome/free-brands-svg-icons";
import headd from "../progect1/MIGHTY STEEL WEBSITE USE/seamintech/titlecard2.png"
import "../../Style.css"
import indiamart from "../progect1/indiamart.png"
import { height } from "@fortawesome/free-brands-svg-icons/fa500px";

export function Bottom() {

    const phoneNumber = "850-898-6866";
    const telnumber="0422-2968866"

    const handleClick = () => {
        window.open(`tel:${phoneNumber}`);
    };
    const handleClicktel=()=>{
        window.open(`tel:${telnumber}`)
    }

    return (
        <>

            <div className="bottomlast">

                {/* <h2 className="Subtitle2 contact"><u>CONTACT</u></h2> */}

                 <div class="row row-cols-1 row-cols-md-3 g-2">
                        <div>
                         <a href='# ' style={{ marginTop: "50px" }}>
                             <img src={navelogo} alt="" className='navelogobottom' />
                             <b style={{ paddingLeft: "10px", color: "black", fontWeight: "bold" }}>
                                 <img style={{height:"60px",width:"330px"}} src={headd}></img>
                             </b>
                         </a>
                     </div>
                    <h5 className="head2 pt-3"><span className="con"> # </span><u><b className="Subtitle2 contact" style={{fontSize:"30px"}}>CONTACT</b></u></h5>
                    <p style={{marginTop:"60px",fontSize:"20px"}}> We provide value added services to our customers with superior Products.</p>
                <div class="col" style={{marginTop:"-20px"}}>
                        <div class="card h-100">
                            <div class="card-body">
                                <h5 className="head2 p-2"><span className="con"> # </span><u><b>Factory</b></u></h5>
                            </div>
                            <img style={{height:"400px",marginTop:"-10px"}} src={loc} alt="" className='' />
                        </div>
                    </div>
                    <div class="col" style={{marginTop:"-20px"}}>
                        <div class="card h-100">
                            <div class="card-body">
                            {/* <img src={navelogo} alt="" style={{height:"20px",width:"20px"}} /> */}
                                <h5 className="head2 p-2"><span className="con"> # </span><u style={{fontSize:"25px",fontWeight:'bold'}}>SEAMINTECH SOLUTIONS</u></h5>
                                <p>S.F.No.399/1A,2B, Vijayakumar layout Road, Behind GCT Nagar-2, Kasthurinaickenpalayam, Vadavalli(Po), Coimbatore - 641041,India.</p>
                                <p onClick={handleClick} style={{ cursor: "pointer" }}> <a href='# ' className='iconsim' onClick={handleClick} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faPhone} className='ibo' shake /></a> +91 85089 86866</p>
                                <p onClick={handleClicktel} style={{ cursor: "pointer" }}> <a href='# ' className='iconsim' onClick={handleClick} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faPhone} className='ibo' shake /></a> 0422-2968866</p>
                                <p> <a href='mailto:mightysteellinks@gmail.com' className='iconsim'><FontAwesomeIcon icon={faEnvelope} className='ibo' /></a><a href="mailto:mightysteellinks@gmail.com" style={{color:"black",textDecoration:"underline"}} className="mail">seamintech@gmail.com</a></p>
                                <p><img style={{height:"20px",width:"23px"}} src={indiamart}/><a style={{marginLeft:"5px",color:"black",textDecoration:"none"}} href="https://www.indiamart.com/mighty-steel-links/profile.html">Indiamart</a></p>
                                <p><FontAwesomeIcon icon={faEnvelope}/><a className='iconsim' style={{color:"black",textDecoration:"underline"}} href="mailto:info@seamintech.com">info@seamintech.com</a></p>
                                <p><FontAwesomeIcon icon={faEnvelope}/><a className='iconsim' style={{color:"black",textDecoration:"underline"}} href="mailto:sales@seamintech.com">sales@seamintech.com</a></p>
                                <p ><FontAwesomeIcon icon={faInternetExplorer}/><a class="Subtitle2 contact " style={{textDecoration:"underline",fontSize:"18px"}} href="https://seamintech.com/">www.seamintech.com</a></p>

                            </div>
                        </div>
                    </div>
                    <div class="col" style={{marginTop:"-20px"}}>
                        <div class="card h-100">
                            <div class="card-body">
                                <h5 className="head2 p-2"><span className="con"> # </span><u><b>Our Location</b></u></h5>
                            </div>
                            <iframe style={{display:"flex",justifyContent:"center", alignItems:"center",minwidth:"450px"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d70325.4547605885!2d76.85898204976957!3d11.053351305394742!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85ff556b6749b%3A0x75533a37aa19cb57!2sMighty%20steel%20links!5e0!3m2!1sen!2sin!4v1721823860407!5m2!1sen!2sin" width="600" height="450" className="gmap"  loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>

            </div>

            <footer className="footerpage">
                <div className="d-flex justify-content-between">
                    <div >
                        <p>@2024 Seamintech Solutions.</p>
                    </div>
                    <div>
                        <p>Website Design by <a href="https://asglobalsofttech.com/"><span className="as"> AS Global SoftTech.</span></a></p>
                    </div>
                </div>
            </footer>
        </>
    );
}
