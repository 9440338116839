import React from 'react';
import navelogo from '../Components/progect1/MIGHTY STEEL WEBSITE USE/seamintech/navlogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import phone from "../Components/progect1/phone.jpg";
import email from "../Components/progect1/email.jpg";
import website from "../Components/progect1/website.png";
import headd from "../Components/progect1/MIGHTY STEEL WEBSITE USE/seamintech/titlecard2.png"
import './Menu.css'; // Assuming you have a separate CSS file for custom styles

export function Menu() {

    const phoneNumber = "850-898-6866";

    const handleClick = () => {
      window.open(`tel:${phoneNumber}`);
    };

    return (
        <div className='websitefont'>
            <div className="Topnavebar">
                <nav className="navbar">
                    <div className="container-fluid">
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex gap-2'>
                                <div className='d-flex align-items-center'>
                                    <a href='# '><img src={navelogo} alt="Logo" className='navelogo' /></a>
                                </div>
                                <div className='d-flex ps-2'>
                                    <div className='col'>
                                    <div>
                                            <a href="# " className='navhead' style={{fontWeight: "900",
                                                    fontFamily:"fantasy" ,
                                                    color: "#03039e",
                                                    textDecoration: "none"}}>
                                             <h4><img src={headd}/></h4>
                                            </a>
                                            </div>

                                        <div>
                                            <a href="# " className='navehead1'>
                                                <p>
                                                    S.F.No.399/1A,2B, Vijayakumar layout Road, Behind GCT Nagar-2,<br />
                                                    Kasthurinaickenpalayam, Vadavalli(Po), Coimbatore - 641041 . 
                                                    <span className='navehead'>
                                                        <br />
                                                        <FontAwesomeIcon icon={faCircleCheck} style={{color: "#ff0000"}} />  
                                                        <span style={{color:"blue"}}>GST</span>  <span className='gst' style={{color:"#32cd32",fontWeight:"bold"}}> 33ACZPH9901N1ZV</span>
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='contact-info'>
                                <div className='contact-item'>
                                    <img src={phone} alt="Phone" className='contact-icon' />
                                    <div className='contact-details'>
                                        <h4 style={{fontSize:"18px"}}>Phone</h4>
                                        <h6 style={{fontSize:"14px",fontWeight:"500"}}>8508986866</h6>
                                    </div>
                                </div>
                                <div className='contact-item'>
                                    <img src={email} alt="Email" className='contact-icon' />
                                    <div className='contact-details'>
                                        <h4 style={{fontSize:"18px"}}>Email</h4>
                                        <a style={{textDecoration:"undedline",color:"black"}} href="mailto:seamintech@gmail.com"><h6>seamintech@gmail.com</h6></a>
                                    </div>
                                </div>
                                <div className='contact-item'>
                                    <img src={website} alt="Website" className='contact-icon' />
                                    <div className='contact-details'>
                                        <h4 style={{fontSize:"18px"}}>Website</h4>
                                        <h6>www.seamintech.com</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="navbaritoms">
                       <a href="/ "><h6>Home</h6></a>
                       <a href="/about "><h6>About</h6></a>
                        <a href="/product "><h6>Products</h6></a>
                        <a href="/case "><h6>Enquiry</h6></a>
                       <a href="/contact "><h6>Contact</h6></a>
                       <div className='navbaritoms2 pe-5'><a href="/case"><h6>Get Quote</h6></a></div>
                    </div>

            </div>
        </div>
    );
}

